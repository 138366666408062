import { environment } from "src/environments/environment";
import { Sport } from "./sport.model";

export const SPORTS: Array<Sport> = [
	{
		code: "EURO",
		shortName: "Euro 2020",
		fullName: "Euro 2020",
		active: false,
		isSoccer: true,
		hasFutures: true,
		fixtureType: "daily",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		jerseyFileExtension: "png",
		navigablePages: [
			// {name: "Hub", path: "/euro", pageCode: "hub"},
			{name: "Predictions", path: "/sport-hub/euro/schedule", pageCode: "schedule"},
			{name: "Futures", path: "/futures/euro", pageCode: "futures"},
			// {name: "Simulator", path: "/sport-hub/euro/simulator", pageCode: "simulator"},
		],
		hideFromMainNav: false,
	},
	{
		code: "AFL",
		shortName: "AFL",
		fullName: "Australian Football League",
		active: false,
		fixtureType: "weekly",
		isSoccer: false,
		hasFutures: true,
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			// {name: "Hub", path: "/afl", pageCode: "hub"},
			{name: "Predictions", path: "/sport-hub/afl/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/afl", pageCode: "best-bets"},
			{name: "Brownlow 2023", path: "/sport-hub/afl/brownlow", pageCode: "brownlow"},
			{name: "Futures", path: "/futures/afl", pageCode: "futures"},
			{name: "News", path: "/news", queryParams: {sport: "afl"}, pageCode: "news"},
			{name: "Schedule Difficulty", shortName: "Schedule Diff", path: "/sport-hub/afl/sos", pageCode: "sos"},
			{name: "Player Ratings", path: "/sport-hub/afl/player-ratings", pageCode: "player-ratings"},
			{name: "Player DNA", path: "/sport-hub/afl/player-dna", pageCode: "player-dna"},
			// {name: "Shot Charting", path: "/sport-hub/afl/shot-charting", pageCode: "shot-charting"},
			{name: "Pressure Gauge", path: "/sport-hub/afl/pressure-gauge", pageCode: "pressure-gauge"},
			{name: "Tipping", path: "/sport-hub/afl/tipping", pageCode: "tipping", hideCondition: !environment.optionalFeatures.includes("tipping")},
		]
	},
	{
		code: "NRL",
		shortName: "NRL",
		fullName: "National Rugby League",
		active: false,
		isSoccer: false,
		hasFutures: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			// {name: "Hub", path: "/nrl", pageCode: "hub"},
			{name: "Predictions", path: "/sport-hub/nrl/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/nrl", pageCode: "best-bets"},
			{name: "Futures", path: "/futures/nrl", pageCode: "futures"},
			{name: "News", path: "/news", queryParams: {sport: "nrl"}, pageCode: "news"},
			{name: "Schedule Difficulty", shortName: "Schedule Diff", path: "/nrl/sos", pageCode: "sos"},
			{name: "True Kicker", path: "/nrl/true-kicker", pageCode: "true-kicker"},
			{name: "Try Location Analysis", shortName: "Try Locations", path: "/nrl/left-right", pageCode: "left-right"},
			{name: "Tipping", path: "/nrl/tipping", pageCode: "tipping", hideCondition: !environment.optionalFeatures.includes("tipping")},
		]
	},
	{
		code: "AFLW",
		shortName: "AFLW",
		fullName: "AFL Women's",
		active: false,
		fixtureType: "weekly",
		isSoccer: false,
		hasFutures: false,
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: false,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			// {name: "Hub", path: "/aflw", pageCode: "hub"},
			{name: "Predictions", path: "/sport-hub/aflw/schedule", pageCode: "schedule"},
			{name: "Tipping", path: "/aflw/tipping", pageCode: "tipping", hideCondition: !environment.optionalFeatures.includes("tipping")},
		]
	},
	{
		code: "NBA",
		shortName: "NBA",
		fullName: "National Basketball Association",
		active: true,
		fixtureType: "daily",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		// hasLiveMatchPredictions: true,
		hasFutures: true,
		isSoccer: false,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/nba/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/nba", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/nba", pageCode: "best-props", hideCondition: !environment.optionalFeatures.includes("props")},
			{name: "Best Trends", path: "/best-trends/nba", pageCode: "trends", hideCondition: !environment.optionalFeatures.includes("trends")},
			{name: "Futures", path: "/futures/nba", pageCode: "futures"},
			{name: "Odds", path: "/best-odds", queryParams: {league: "nba", val: "BKP"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
			{name: "News", path: "/news", queryParams: {sport: "nba"}, pageCode: "news"},
			{name: "Tipping", path: "/nba/tipping", pageCode: "tipping", hideCondition: !environment.optionalFeatures.includes("tipping")},
			// {name: "Simulator", path: "/nba/simulator", pageCode: "simulator"},
		]
	},
	{
		code: "NFL",
		shortName: "NFL",
		fullName: "National Football League",
		active: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		// hasLiveMatchPredictions: true,
		hasFutures: true,
		isSoccer: false,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/nfl/schedule", pageCode: "schedule"},
			// {name: "Super Bowl 2023", path: "/super-bowl", pageCode: "super-bowl"},
			{name: "Best Bets", path: "/best-bets/nfl", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/nfl", pageCode: "best-props", hideCondition: !environment.optionalFeatures.includes("props")},
			{name: "Futures", path: "/futures/nfl", pageCode: "futures"},
			{name: "Odds", path: "/best-odds", queryParams: {league: "nfl", val: "FBP"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
			{name: "News", path: "/news", queryParams: {sport: "nfl"}, pageCode: "news"},
			{name: "Tipping", path: "/nfl/tipping", pageCode: "tipping", hideCondition: !environment.optionalFeatures.includes("tipping")},
		]
	},
	{
		code: "SWC",
		shortName: "World Cup 2022",
		fullName: "World Cup 2022",
		active: false,
		isSoccer: true,
		hasFutures: false,
		fixtureType: "special",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: false,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		jerseyFileExtension: "png",
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/swc/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/swc", pageCode: "best-bets"},
			{name: "Futures", path: "/futures/swc", pageCode: "futures"},
			// {name: "Odds", path: "/best-odds", queryParams: {league: "swc", val: "SOW"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
			{name: "News", path: "/news", queryParams: {sport: "swc"}, pageCode: "news"},
			{name: "Preview", path: "/sport-hub/swc/preview", pageCode: "preview"},
			{name: "Simulator", path: "/sport-hub/swc/simulator", pageCode: "simulator"}
		]
	},
	{
		code: "CFB",
		shortName: "College Football",
		fullName: "College Football",
		active: false,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		// hasLiveMatchPredictions: true,
		hasFutures: true,
		isSoccer: false,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/cfb/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/cfb", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/cfb", pageCode: "best-props", hideCondition: !environment.optionalFeatures.includes("props")},
			{name: "Futures", path: "/futures/cfb", pageCode: "futures"},
			{name: "Odds", path: "/best-odds", queryParams: {league: "cfb", val: "FBC"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
			{name: "News", path: "/news", queryParams: {sport: "cfb"}, pageCode: "news"},
			{name: "Tipping", path: "/cfb/tipping", pageCode: "tipping", hideCondition: !environment.optionalFeatures.includes("tipping")},
		]
	},
	{
		code: "CBB",
		shortName: "College Basketball",
		fullName: "College Basketball",
		active: true,
		isSoccer: false,
		hasFutures: true,
		fixtureType: "daily",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/cbb/schedule", pageCode: "schedule"},
			// {name: "March Madness", path: "/march-madness", pageCode: "march-madness", hideCondition: environment.siteTitle !== "Dimers"},
			{name: "Best Bets", path: "/best-bets/cbb", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/cbb", pageCode: "best-props", hideCondition: !environment.optionalFeatures.includes("props")},
			{name: "Futures", path: "/futures/cbb", pageCode: "futures"},
			{name: "Odds", path: "/best-odds", queryParams: {league: "cbb", val: "BKC"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
			{name: "News", path: "/news", queryParams: {sport: "cbb"}, pageCode: "news"},
			{name: "Tipping", path: "/cbb/tipping", pageCode: "tipping", hideCondition: !environment.optionalFeatures.includes("tipping")},
			// {name: "Simulator", path: "/cbb/simulator", pageCode: "simulator"}
		]
	},
	{
		code: "MLB",
		shortName: "MLB",
		fullName: "Major League Baseball",
		active: false,
		isSoccer: false,
		hasFutures: true,
		fixtureType: "daily",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/mlb/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/mlb", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/mlb", pageCode: "best-props", hideCondition: !environment.optionalFeatures.includes("props")},
			{name: "Best Trends", path: "/best-trends/mlb", pageCode: "trends", hideCondition: !environment.optionalFeatures.includes("trends")},
			{name: "Futures", path: "/futures/mlb", pageCode: "futures"},
			{name: "Odds", path: "/best-odds", queryParams: {league: "mlb", val: "BBM"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
			{name: "News", path: "/news", queryParams: {sport: "mlb"}, pageCode: "news"}
		]
	},
	{
		code: "BBL",
		shortName: "Big Bash",
		fullName: "Big Bash League",
		active: true,
		isSoccer: false,
		hasFutures: true,
		fixtureType: "special",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: false,
		// usesOfficialLogos: true,
		jerseyFileKey: "DisplayName",
		showInScoreStrip: true,
		navigablePages: [
			// {name: "Hub", path: "/bbl", pageCode: "hub"},
			{name: "Predictions", path: "/sport-hub/bbl/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/bbl", pageCode: "best-bets"},
			{name: "Futures", path: "/futures/bbl", pageCode: "futures"},
			{name: "News", path: "/news", queryParams: {sport: "bbl"}, pageCode: "news"},
			// {name: "Player DNA", path: "/bbl/player-dna", pageCode: "player-dna"}
		]
	},
	{
		code: "NHL",
		shortName: "NHL",
		fullName: "National Hockey League",
		active: true,
		isSoccer: false,
		hasFutures: true,
		fixtureType: "daily",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/nhl/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/nhl", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/nhl", pageCode: "best-props", hideCondition: !environment.optionalFeatures.includes("props")},
			{name: "Futures", path: "/futures/nhl", pageCode: "futures"},
			{name: "Odds", path: "/best-odds", queryParams: {league: "nhl", val: "HKN"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
			{name: "News", path: "/news", queryParams: {sport: "nhl"}, pageCode: "news"},
			{name: "Tipping", path: "/nhl/tipping", pageCode: "tipping", hideCondition: !environment.optionalFeatures.includes("tipping")},
		]
	},
	{
		code: "TEN",
		shortName: "Tennis",
		fullName: "Tennis",
		active: true,
		isSoccer: false,
		hasFutures: false,
		fixtureType: "special",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: false,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/ten/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/ten", pageCode: "best-bets"},
			{name: "News", path: "/news", queryParams: {sport: "ten"}, pageCode: "news"},
			{name: "Rankings", path: "/sport-hub/ten/rankings", pageCode: "rankings"},
			// {name: "Men's Simulator", shortName: "M Simulator", path: "/sport-hub/ten/simulator", queryParams: {tid: "2567"}},
			// {name: "Women's Simulator", shortName: "W Simulator", path: "/sport-hub/ten/simulator", queryParams: {tid: "2571"}},
			{name: "Men's Futures", shortName: "M Futures", path: "/sport-hub/ten/futures", queryParams: {tid: "2567"}},
			{name: "Women's Futures", shortName: "W Futures", path: "/sport-hub/ten/futures", queryParams: {tid: "2571"}}
		]
	},
	{
		code: "EPL",
		shortName: "Premier League",
		fullName: "English Premier League",
		active: true,
		isSoccer: true,
		hasFutures: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/epl/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/epl", pageCode: "best-bets"},
			{name: "Futures", path: "/futures/epl", pageCode: "futures"},
			{name: "Odds", path: "/best-odds", queryParams: {league: "epl", val: "SOE"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
			{name: "News", path: "/news", queryParams: {sport: "epl"}, pageCode: "news"},
		]
	},
	{
		code: "ESP",
		shortName: "La Liga",
		fullName: "La Liga",
		active: true,
		isSoccer: true,
		hasFutures: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/esp/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/esp", pageCode: "best-bets"},
			{name: "Futures", path: "/futures/esp", pageCode: "futures"},
			{name: "Odds", path: "/best-odds", queryParams: {league: "esp", val: "SOS"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
			{name: "News", path: "/news", queryParams: {sport: "esp"}, pageCode: "news"},
		]
	},
	{
		code: "SLC",
		shortName: "Leagues Cup",
		fullName: "Leagues Cup",
		active: false,
		isSoccer: true,
		hasFutures: true,
		fixtureType: "special",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		jerseyFileExtension: "png",
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/slc/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/slc", pageCode: "best-bets"},
			{name: "Futures", path: "/futures/slc", pageCode: "futures"},
			// {name: "Odds", path: "/best-odds", queryParams: {league: "slc", val: "SOX"}, pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "slc"}, pageCode: "news"},
		]
	},
	{
		code: "MLS",
		shortName: "MLS",
		fullName: "Major League Soccer",
		active: false,
		isSoccer: true,
		hasFutures: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/mls/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/mls", pageCode: "best-bets"},
			{name: "Futures", path: "/futures/mls", pageCode: "futures"},
			{name: "Odds", path: "/best-odds", queryParams: {league: "mls", val: "SOM"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
			{name: "News", path: "/news", queryParams: {sport: "mls"}, pageCode: "news"},
		]
	},
	{
		code: "ALG",
		shortName: "A-League",
		fullName: "A-League",
		active: false,
		isSoccer: true,
		hasFutures: false,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: false,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			// {name: "Hub", path: "/alg", pageCode: "hub"},
			{name: "Predictions", path: "/sport-hub/alg/schedule", pageCode: "schedule"},
			{name: "Futures", path: "/futures/alg", pageCode: "futures"},
			{name: "Tipping", path: "/alg/tipping", pageCode: "tipping", hideCondition: !environment.optionalFeatures.includes("tipping")},
		]
	},
	{
		code: "BUN",
		shortName: "Bundesliga",
		fullName: "Bundesliga",
		active: false,
		isSoccer: true,
		hasFutures: false,
		fixtureType: "weekly",
		valueSummaryEligible: false,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: false,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Odds", path: "/best-odds", queryParams: {league: "bun", val: "SOG"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
		]
	},
	{
		code: "SRU",
		shortName: "Super Rugby",
		fullName: "Super Rugby",
		active: false,
		isSoccer: false,
		hasFutures: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: false,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			// {name: "Hub", path: "/sru", pageCode: "hub"},
			{name: "Predictions", path: "/sport-hub/sru/schedule", pageCode: "schedule"},
			{name: "Futures", path: "/futures/sru", pageCode: "futures"},
			{name: "Tipping", path: "/sru/tipping", pageCode: "tipping", hideCondition: !environment.optionalFeatures.includes("tipping")},
		]
	},
	{
		code: "TRC",
		shortName: "Rugby Championship",
		fullName: "The Rugby Championship",
		active: false,
		isSoccer: false,
		hasFutures: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: false,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			// {name: "Hub", path: "/trc", pageCode: "hub"},
			{name: "Predictions", path: "/sport-hub/trc/schedule", pageCode: "schedule"},
			{name: "Futures", path: "/futures/trc", pageCode: "futures"},
			{name: "Tipping", path: "/trc/tipping", pageCode: "tipping", hideCondition: !environment.optionalFeatures.includes("tipping")},
		]
	},
	{
		code: "LUN",
		shortName: "Ligue 1",
		fullName: "Ligue 1",
		active: false,
		isSoccer: true,
		hasFutures: false,
		fixtureType: "weekly",
		valueSummaryEligible: false,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: false,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Odds", path: "/best-odds", queryParams: {league: "lun", val: "SOF"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
		]
	},
	{
		code: "SRA",
		shortName: "Serie A",
		fullName: "Serie A",
		active: false,
		isSoccer: true,
		hasFutures: false,
		fixtureType: "weekly",
		valueSummaryEligible: false,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: false,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Odds", path: "/best-odds", queryParams: {league: "sra", val: "SOI"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
		]
	},
	{
		code: "GOLF",
		shortName: "Golf",
		fullName: "Golf",
		active: true,
		fixtureType: "special",
		valueSummaryEligible: false,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: false,
		// usesOfficialLogos: true,
		isSoccer: false,
		hasFutures: false,
		jerseyFileKey: "Abv",
		showInScoreStrip: false,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/golf/next", pageCode: "tournament"},
			{name: "Rankings", path: "/sport-hub/golf/rankings", pageCode: "rankings"},
			// {name: "News", path: "/news", queryParams: {sport: "golf"}, pageCode: "news"},
			{name: "Matchup Tool", path: "/sport-hub/golf/h2h", queryParams: {tourn: "next"}, pageCode: "h2h"},
		]
	},
	{
		code: "LMX",
		shortName: "Liga MX",
		fullName: "Liga MX",
		active: false,
		isSoccer: true,
		hasFutures: false,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: true,
		// usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		jerseyFileExtension: "png",
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/lmx/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/lmx", pageCode: "best-bets"},
			// {name: "Futures", path: "/futures/lmx", pageCode: "futures"},
			{name: "Odds", path: "/best-odds", queryParams: {league: "lmx", val: "SOX"}, pageCode: "best-odds", hideCondition: !environment.optionalFeatures.includes("best-odds")},
			{name: "News", path: "/news", queryParams: {sport: "lmx"}, pageCode: "news"},
		]
	},
	{
		code: "SOO",
		shortName: "Origin",
		fullName: "State of Origin",
		active: false,
		hasFutures: false,
		valueSummaryEligible: false,
		propsValueSummaryEligible: false,
		navigablePages: [
			{name: "Predictions", path: "/sport-hub/soo/schedule", pageCode: "schedule"},
			{name: "News", path: "/news", queryParams: {sport: "soo"}, pageCode: "news"},
		],
		fixtureType: "special",
		// hasLiveMatchPredictions: false,
		isSoccer: false,
		// usesOfficialLogos: true,
		showInScoreStrip: true,
		jerseyFileKey: "Abv",
	},
	{
		code: "RACING",
		shortName: "Racing",
		fullName: "Horse Racing",
		active: true,
		fixtureType: "special",
		valueSummaryEligible: false,
		propsValueSummaryEligible: false,
		// hasLiveMatchPredictions: false,
		// usesOfficialLogos: true,
		isSoccer: false,
		hasFutures: false,
		jerseyFileKey: "Abv",
		showInScoreStrip: false,
		navigablePages: [
            {name: "News", path: "/news", queryParams: {sport: "racing"}, pageCode: "news"}
		]
	}
]