// import { DISQUS_SHORTNAME } from "ngx-disqus";

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SidebarModule } from './sidebar/sidebar.module';
import { GeneralModule } from './general/general.module';

import { RollbarService, rollbarFactory, RollbarErrorHandler } from "./rollbar";
import { GoogleTagManagerModule } from './angular-google-tag-manager.module';

import { CookieModule } from "ngx-cookie";
import { NthModule } from './nth/nth.module';
import { NthPipe } from './nth/nth.pipe';
import { MatchPipesModule } from './match-pipes/match-pipes.module';
import { IsLivePipe } from './match-pipes/is-live.pipe';
import { IsPreMatchOnlyPipe } from './match-pipes/is-pre-match-only.pipe';
import { IsOverPipe } from './match-pipes/is-over.pipe';
import { IsPausedPipe } from './match-pipes/is-paused.pipe';
import { BookBackgroundColourPipe } from './book-pipes/book-background-colour.pipe';
import { BookForegroundColourPipe } from './book-pipes/book-foreground-colour.pipe';
import { BookIconAltPipe } from './book-pipes/book-icon-alt.pipe';
import { BookIconPipe } from './book-pipes/book-icon.pipe';
import { BookLogoAltPipe } from './book-pipes/book-logo-alt.pipe';
import { BookLogoPipe } from './book-pipes/book-logo.pipe';
import { BetService } from './bet.service';
import { DOCUMENT } from '@angular/common';
import { BlogService } from './blog.service';
import { StatsInsiderBlogService } from './blog.statsinsider.service';
import { GamblingTodayBlogService } from './blog.gamblingtoday.service';
import { RadarSportsBlogService } from './blog.radarsports.service';
import { HoneycombService } from './honeycomb.service';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { ToCelsiusPipe } from './temperature-pipes/to-celsius.pipe';
import { ToFahrenheitPipe } from './temperature-pipes/to-fahrenheit.pipe';
import { provideClientHydration } from '@angular/platform-browser';
import { GoogleTagManagerService } from './angular-google-tag-manager.service';
import { GoogleTagManagerShellService } from './google-tag-manager-shell.service';
import { register } from "swiper/element/bundle";

declare global {
	interface Window { analytics: any; smartlook: any; }
}

// Register Swiper Elements
register();

class CustomUrlSerialiser implements UrlSerializer {
	parse(url: any): UrlTree {
	  let dus = new DefaultUrlSerializer();
	  console.log(url);
	  if(/[\(\)]/g.test(url)) {
		url = url.replace(/[\(\)]/g, '/');
	  }
	  return dus.parse(url);
	}
	serialize(tree: UrlTree): any {
	  let dus = new DefaultUrlSerializer(),
	  path = dus.serialize(tree);
	  return path;
	}
  }

function setBlogService(): any {
	if (environment.siteTitle === "Radar Sports") return RadarSportsBlogService;
	if (environment.siteTitle === "Gambling Today") return GamblingTodayBlogService;
	if (environment.siteTitle === "Stats Insider") return StatsInsiderBlogService;

	return BlogService;
}

function initialiseApp(betService: BetService, rendererFactory: RendererFactory2, document: Document, honeycomb: HoneycombService): () => Promise<any> {
	return () => new Promise<void>((resolve) => {
		honeycomb.sendMessage({
			message: "initialiseApp"
		});
		const renderer = rendererFactory.createRenderer(null, null);
		betService.setCustomBookmakerLinks(environment.customBookmakerLinks);
		if (environment.colourValues) {
			Object.keys(environment.colourValues).forEach(k => {
				renderer.setStyle(document.documentElement, `--${k}`, `${environment.colourValues[k]}`, 2);
			})
		}
		if (environment.fontFamily) {
			renderer.setStyle(document.documentElement, `--font-main`, environment.fontFamily, 2);
			if (environment.blogFontFamily) {
				renderer.setStyle(document.documentElement, `--font-blog`, environment.blogFontFamily, 2);
			} else {
				renderer.setStyle(document.documentElement, `--font-blog`, environment.fontFamily, 2);
			}
		}
		if (environment.fontImport) {
			const link: HTMLLinkElement = document.createElement("link");
			link.setAttribute("rel", "stylesheet");
			link.setAttribute("href", environment.fontImport);
			link.media = "print";
			link.onload = () => {link.media = 'all'; link.onload = null;};

			document.head.appendChild(link);
		}

		if (environment.blogFontImport) {
			const link: HTMLLinkElement = document.createElement("link");
			link.setAttribute("rel", "stylesheet");
			link.setAttribute("href", environment.blogFontImport);
			link.media = "print";
			link.onload = () => {link.media = 'all'; link.onload = null;};

			document.head.appendChild(link);
		}

		if (environment.googleSiteVerificationCode) {
			const meta: HTMLMetaElement = document.createElement("meta");
			meta.name = "google-site-verification";
			meta.content = environment.googleSiteVerificationCode;
			document.head.appendChild(meta);
		}

		// if (environment.onetrustID) {
		// 	const otAutoScript: HTMLScriptElement = document.createElement("script");
		// 	otAutoScript.setAttribute("type", "text/javascript");
		// 	otAutoScript.setAttribute("src", `https://cdn.cookielaw.org/consent/${environment.onetrustID}/OtAutoBlock.js`)
		// 	document.head.appendChild(otAutoScript);

		// 	const otScript: HTMLScriptElement = document.createElement("script");
		// 	otScript.setAttribute("type", "text/javascript");
		// 	otScript.setAttribute("charset", "UTF-8");
		// 	otScript.setAttribute("src", "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js")
		// 	otScript.setAttribute("data-domain-script", environment.onetrustID);
		// 	document.head.appendChild(otScript);
		// }

		if (environment.twitterUsername) {
			const meta: HTMLMetaElement = document.createElement("meta");
			meta.setAttribute("name", "twitter:site");
			meta.setAttribute("content", "@" + environment.twitterUsername);
			document.head.appendChild(meta);
		}

		if (environment.defaultMetaDescription) {
			const descMeta = document.createElement("meta");
			descMeta.setAttribute("name", "description");
			descMeta.setAttribute("content", environment.defaultMetaDescription);
			const ogMeta = document.createElement("meta");
			ogMeta.setAttribute("property", "og:description");
			ogMeta.setAttribute("content", environment.defaultMetaDescription);
			const twitterMeta = document.createElement("meta");
			twitterMeta.setAttribute("name", "twitter:description");
			twitterMeta.setAttribute("content", environment.defaultMetaDescription);

			document.head.appendChild(descMeta);
			document.head.appendChild(ogMeta);
			document.head.appendChild(twitterMeta);

		}

		if (environment.siteTitle) {
			const title: HTMLTitleElement = document.createElement("title");
			title.innerHTML = environment.siteTitle;
			document.head.appendChild(title);

			const ogTitle: HTMLMetaElement = document.createElement("meta");
			ogTitle.setAttribute("property", "og:title");
			ogTitle.setAttribute("content", environment.siteTitle);
			document.head.appendChild(ogTitle);
		}

		if (environment.defaultMetaImage) {
			const ogImage = document.createElement("meta");
			ogImage.setAttribute("property", "og:image");
			ogImage.setAttribute("content", environment.defaultMetaImage);
			const twitterImage = document.createElement("meta");
			twitterImage.setAttribute("name", "twitter:image");
			twitterImage.setAttribute("content", environment.defaultMetaImage);

			document.head.appendChild(ogImage);
			document.head.appendChild(twitterImage);
		}

		if (environment.iconSet) {
			if (environment.iconSet.favicon) {
				const iconLink = document.createElement("link");
				iconLink.rel = "icon";
				iconLink.type = "image/x-icon";
				iconLink.href = environment.iconSet.favicon;
				document.head.appendChild(iconLink);
			}

			if (environment.iconSet["180"]) {
				const iconLink = document.createElement("link");
				iconLink.rel = "apple-touch-icon";
				iconLink.setAttribute("sizes", "180x180");
				iconLink.href = environment.iconSet["180"];
				document.head.appendChild(iconLink);
			}

			if (environment.iconSet["16"]) {
				const iconLink = document.createElement("link");
				iconLink.rel = "icon";
				iconLink.type = "image/png";
				iconLink.setAttribute("sizes", "16x16");
				iconLink.href = environment.iconSet["16"];
				document.head.appendChild(iconLink);
			}

			if (environment.iconSet["32"]) {
				const iconLink = document.createElement("link");
				iconLink.rel = "icon";
				iconLink.type = "image/png";
				iconLink.setAttribute("sizes", "32x32");
				iconLink.href = environment.iconSet["32"];
				document.head.appendChild(iconLink);
			}

			if (environment.iconSet["96"]) {
				const iconLink = document.createElement("link");
				iconLink.rel = "icon";
				iconLink.type = "image/png";
				iconLink.href = environment.iconSet["96"];
				iconLink.setAttribute("sizes", "96x96");
				document.head.appendChild(iconLink);
			}

		}

		if (environment.manifestPath) {
			const link: HTMLLinkElement = document.createElement("link");
			link.setAttribute("rel", "manifest");
			link.setAttribute("href", environment.manifestPath);
			document.head.appendChild(link);
		}

		// if (environment.smartlookID && typeof window !== "undefined") {
		// 	// (window as any).smartlook||(function(d) { var smartlook: any;var o: any=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
		// 	// var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript'; c.charset='utf-8';
		// 	// c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c); })(document);
		// 	console.log("about to call smartlook");
		// 	(window as any).smartlook('init', environment.smartlookID, { region: 'eu' });
		// }
		
		if (environment.siteTitle === "Dimers") {

			var analytics=(window as any).analytics=(window as any).analytics||[];
			if(!analytics.initialize)
			if(analytics.invoked)
			window.console&&console.error&&console.error("Segment snippet included twice.");
			else{
				analytics.invoked=!0;
				analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track",
				"ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];
				analytics.factory=function(e){
					return function(){
						var t=Array.prototype.slice.call(arguments);
						t.unshift(e);
						analytics.push(t);
						return analytics
					}
				};
				for(var e=0;e<analytics.methods.length;e++){
					var key=analytics.methods[e];
					analytics[key]=analytics.factory(key)
				}
				analytics.load=function(key,e){
					var t=document.createElement("script");
					t.type="text/javascript";
					t.async=!0;
					t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
					var n=document.getElementsByTagName("script")[0];
					n.parentNode.insertBefore(t,n);
					analytics._loadOptions=e
				};
				analytics._writeKey="HJjI8jUfB2iGYEFs4QkDogL30FBatHXE";;
				analytics.SNIPPET_VERSION="4.15.3";
				analytics.load("HJjI8jUfB2iGYEFs4QkDogL30FBatHXE");
				analytics.page();
			}

		}

		if (environment.optionalFeatures.includes("taboola") && typeof window !== "undefined") {
			(window as any)._taboola = (window as any)._taboola || [];
			(window as any)._taboola.push({article:'auto'});
			const e = document.createElement("script");
			const f = document.getElementsByTagName("script")[0];
			const u = "//cdn.taboola.com/libtrc/ciphersportstechnologygroup-network/loader.js";
			const i = "tb_loader_script";

			if (!document.getElementById(i)){
				e.async = true;
				e.src = u;
				e.id = i;
				f.parentNode.insertBefore(e, f);
			}
			if(window.performance && typeof window.performance.mark == 'function')
			{window.performance.mark('tbl_ic');}
		}
		
		resolve();
	})

}

declare global {
	interface Window { analytics: any; smartlook: any; }
}

@NgModule({
	declarations: [
		AppComponent,
	],
	providers: [
		{ provide: ErrorHandler, useClass: RollbarErrorHandler},
		{ provide: RollbarService, useFactory: rollbarFactory },
		{ provide: APP_INITIALIZER, useFactory: initialiseApp, deps: [BetService, RendererFactory2, DOCUMENT, HoneycombService], multi: true},
		{ provide: BlogService, useClass: setBlogService() },
		{ provide: GoogleTagManagerService, useClass: environment.gtmKey ? GoogleTagManagerService : GoogleTagManagerShellService},
		{ provide: UrlSerializer, useClass: CustomUrlSerialiser },
		NthPipe,
		IsLivePipe,
		IsPreMatchOnlyPipe,
		IsOverPipe,
		IsPausedPipe,
		BookBackgroundColourPipe,
		BookForegroundColourPipe,
		BookIconAltPipe,
		BookIconPipe,
		BookLogoAltPipe,
		BookLogoPipe,
		ToFahrenheitPipe,
		ToCelsiusPipe,
		provideClientHydration(),
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		HttpClientModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		FontAwesomeModule,
		BreadcrumbModule,
		SidebarModule,
		GeneralModule,
		HttpClientModule,
		NthModule,
		MatchPipesModule,
		NgxGoogleAnalyticsModule.forRoot(environment.gaKey),
		NgxGoogleAnalyticsRouterModule,
		environment.gtmKey ? [
			GoogleTagManagerModule.forRoot({
				id: environment.gtmKey,
			})
		] : [],
		CookieModule.forRoot(),
		BreadcrumbModule,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
