<!-- <p>display-ad works!</p> -->
<!-- <div class="ad-placeholder">
	DISPLAY AD PLACEHOLDER
</div> -->
<!-- <a class="ad-ctr"
	href="http://wlbetamerica.adsrv.eacdn.com/C.ashx?btag=a_2559b_2321c_&affid=1997&siteid=2559&adid=2321&c="
	target="_blank"
	rel="noreferrer">
	<img class="ad-image large-only" loading="lazy"
		src="https://cdn.ciphersports.io/images/dimers-site/banner-ads/twinspires-desktop.gif"
		alt="$1000 Risk-Free Bet with TwinSpires Sports! Use Promo Code: BET1000. Click here to play now! New customers only. Void where prohibited. T&Cs apply.">
	<img class="ad-image small-only" loading="lazy"
		src="https://cdn.ciphersports.io/images/dimers-site/banner-ads/twinspires-mobile.gif"
		alt="$1000 Risk-Free Bet with TwinSpires Sports! Use Promo Code: BET1000. Click here to play now! New customers only. Void where prohibited. T&Cs apply.">
</a> -->

<ng-container *ngIf="environment.googleAdsConfig">
	<div [id]='adID' *ngIf="type === 'main-column'" class="google-ad main-column">
	</div>
	
	<div [id]='adID' *ngIf="type === 'main-column2'" class="google-ad main-column">
	</div>
	
	<div [id]='adID' *ngIf="type === 'main-column3'" class="google-ad main-column">
	</div>
	
	<div [id]='adID' *ngIf="type === 'sidebar-short'" class="google-ad sidebar-short">
	</div>
	
	<div [id]='adID' *ngIf="type === 'sidebar-multi'" class="google-ad sidebar-multi">
	</div>
	
	<div [id]='adID' *ngIf="type === 'header'" class="google-ad full-width">
	</div>
	
	<div [id]='adID' *ngIf="type === 'footer'" class="google-ad full-width">
	</div>
</ng-container>

<ng-container *ngIf="environment.optionalFeatures.includes('placeholder-ads')">
	<img *ngIf="['header', 'footer'].includes(type)"
		class="ad-placeholder super-large-only"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/draftkings-970x90.png"
		alt=""
	>
	<img *ngIf="['header', 'footer'].includes(type)"
		class="ad-placeholder regular-large-only"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-728x90.gif"
		alt=""
	>
	<img *ngIf="['main-column', 'main-column2', 'main-column3'].includes(type)"
		class="ad-placeholder large-only"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-728x90.gif"
		alt=""
	>
	<img *ngIf="['main-column', 'main-column2', 'main-column3', 'header', 'footer'].includes(type)"
		class="ad-placeholder small-only"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-300x50.gif"
		alt=""
	>
	<img *ngIf="['sidebar-short'].includes(type)"
		class="ad-placeholder"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-300x250.gif"
		alt=""
	>
	<img *ngIf="['sidebar-multi'].includes(type)"
		class="ad-placeholder"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-300x600.png"
		alt=""
	>
</ng-container>