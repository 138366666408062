export const environment = {
	production: true,
	localeString: "US",
	bookmakers: [],
    rollbarAccessToken: "bde4a7d2849740ef8ee0ae254d18a4ec",
	sportExclusiveBookmakers: {},
	futuresBookmakerOverrideSports: [],
	auth0Domain: undefined,
	auth0AudienceDomain: undefined,
	auth0ClientId: undefined,
	defaultLogo: "https://cdn.ciphersports.io/images/gambling-today/gt-ondark.svg",
	availableSports: [],
	officialLogoSports: [],
	liveCoverageSports: [],
	oddsDisplayFormat: "american",
    temperatureDisplay: "fahrenheit",
	gaKey: "G-VBEP8KZZ4R",
	gtmKey: "GTM-TRQPKL7",
	apiDomain: "https://levy-edge.statsinsider.com.au",
	blockDomain: "https://content-blocks-node.azurewebsites.net",
	dimersApiDomain: "https://cdn.dimers.com",
    dimersUncachedDomain: "https://api.dimers.com",
	dimersGeoDomain: "https://apidev.statsinsider.com.au",
	sharpSportsKey: undefined,
	siteTitle: "Gambling Today",
	siteTitlePossessive: "Gambling Today's",
	twitterUsername: undefined,
	facebookURL: undefined,
	instagramURL: undefined,
	discordURL: undefined,
	canonicalDomain: "https://www.gambling-today.com",
    ampDomain: undefined,
	defaultMetaDescription: "Gambling Today is your home for the latest online gambling news and information.",
	defaultMetaImage: "https://cdn.ciphersports.io/images/gambling-today/default-meta.jpg",
	iconSet: {
		"favicon": "https://cdn.ciphersports.io/images/gambling-today/favicon.ico",
		"16": "https://cdn.ciphersports.io/images/gambling-today/favicon-16x16.png",
		"32": "https://cdn.ciphersports.io/images/gambling-today/favicon-32x32.png",
		"180": "https://cdn.ciphersports.io/images/gambling-today/apple-touch-icon.png",
		"192": "https://cdn.ciphersports.io/images/gambling-today/android-chrome-192x192.png",
		"512": "https://cdn.ciphersports.io/images/gambling-today/android-chrome-512x512.png",
	},
	customBookmakerLinks: {},
	ballIconFunction: (sportCode: string, colour: string): string => {
        return "";
	},
	googleAdsConfig: {
		customerID: "22095247501",
		adSpaces: {
			"header": {
				name: "gamblingtoday-header",
				id: "div-gpt-ad-1688534259362-0"
			},
			"footer": {
				name: "gamblingtoday-footer",
				id: "div-gpt-ad-1688534292974-0"
			},
			"main-column": {
				name: "gamblingtoday-main-column",
				id: "div-gpt-ad-1688534374897-0"
			},
			"main-column2": {
				name: "gamblingtoday-main-column2",
				id: "div-gpt-ad-1688534413908-0"
			},
			"main-column3": {
				name: "gamblingtoday-main-column3",
				id: "div-gpt-ad-1688534432441-0"
			},
			"sidebar-short": {
				name: "gamblingtoday-sidebar-short",
				id: "div-gpt-ad-1688534467360-0"
			},
			"sidebar-multi": {
				name: "gamblingtoday-sidebar-multi",
				id: "div-gpt-ad-1688534447897-0"
			},

		}
	},
	aboutPageHTML: ``,
	privacyPolicyHTML: ``,
	termsPageHTML: ``,
	homePageLayout: "two-column",
	footerLinkColumnPages: [
        {title: "About Us", href: "/about-us", queryParams: {}},
        {title: "Contact Us", href: "mailto:contact@gambling-today.com", queryParams: {}, strictHref: true},
        {title: "Writers", href: "/news/authors", queryParams: {}},
        {title: "Privacy Policy", href: "/privacy-policy", queryParams: {}},
        {title: "Terms of Service", href: "/terms-of-service", queryParams: {}},
        {title: "Sitemap", href: "/sitemap", queryParams: {}},
        {title: "Responsible Gaming", href: "/responsible-gaming", queryParams: {}},
        // {title: "Casino 101", href: "/sports-betting-101", queryParams: {}},
        // {title: "Casino Reviews", href: "/betting", queryParams: {}},
    ],
	footerDataProviders: [],
	footerDisclaimerHTML: `
		<p>Disclaimer: All information on this site is for entertainment purposes only.
			We do NOT accept bets of any kind. The information we provide is accurate and trustworthy
			to help you make informed decisions. When you click or tap on a link on Gambling Today leading
			to a third-party website with which we have a commercial arrangement (such as an online casino operator),
			we may earn referral fees. Gambling Today neither endorses
			nor encourages illegal or irresponsible gambling in any form. Before
			placing any wagers with a betting site, it's essential to check the online gambling
			regulations in your jurisdiction or state, as they may vary. If you or someone
			you know has a gambling problem, crisis counseling and referral services can be
			accessed by calling <a href="https://www.1800gambler.net/" target="_blank">1-800-GAMBLER</a>.</p>
		<p>Copyright © 2023 Gambling Today. All Rights Reserved. Proudly part of Cipher Sports Technology Group,
			located at 902A Broadway, Floors 6 & 7, New York, NY 10010, United States of America.</p>
	`,
	optionalFeatures: [
		"newsletter",
		"welcome-offers",
		"best-offers",
		// "best-books",
        // "adhesive-banner",
	],
    newsletterProvider: "placeholder",
	contactEmailAddress: "contact@gambling-today.com",
	matchFirstTeam: "away",
	fontFamily: "\"Work Sans\", sans-serif",
	fontImport: "https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap",
	blogFontFamily: undefined,
	blogFontImport: undefined,
	onetrustID: undefined,
    smartlookID: "dee3b1ae07f49f6025e1cea3df16d71762dc721e",
	colourValues: {
		"background-tone": "255 255 255",
		"foreground-tone": "0 0 0",
		
		"highlight": "70 47 204",
		"highlight-contrast": "255 255 255",
		
		"highlight-lighter": "105 86 216",
		"block-toggle-selected": "70 47 204",
		"block-toggle-selected-contrast": "255 255 255",
		"prob-progression-background": "146 46 170",

		"highlight-secondary": "146 46 170",
		"highlight-secondary-contrast": "255 255 255",

		"dark-primary": "39 37 53",
		"dark-primary-contrast": "255 255 255",

		"dark-secondary": "61 58 83",
		"dark-secondary-contrast": "255 255 255",
		
		"dark-primary-lighter": "61 58 83",

		"highlight-darker": "49 33 143",
	},
	manifestPath: "./assets/manifests/gambling-today.json",
    adsFilePath: undefined,
	additionalNavOptions: [
		{title: "Bonuses", subLinks: [
			// {title: "No Deposit Bonus", routerLink: "/no-deposit-bonus", queryParams: {}},
			// {title: "New Casino Bonus", routerLink: "/new-casino-bonus", queryParams: {}},
			{title: "Online Casino Bonuses", routerLink: "/online-casinos/bonuses", queryParams: {}},
			{title: "bet365 Casino Bonuses", routerLink: "/bonuses/bet365-casino", queryParams: {}},
			{title: "DraftKings Casino Bonuses", routerLink: "/bonuses/draftkings-casino", queryParams: {}},
			{title: "BetMGM Casino Bonuses", routerLink: "/bonuses/betmgm-casino", queryParams: {}},
			{title: "FanDuel Casino Bonuses", routerLink: "/bonuses/fanduel-casino", queryParams: {}},
		]},
		{title: "Online Casinos", subLinks: [
			{title: "US Online Casinos", routerLink: "/online-casinos", queryParams: {}},
			{title: "PA Online Casinos", routerLink: "/online-casinos/pennsylvania", queryParams: {}},
			// {title: "NJ Online Casino", routerLink: "/online-casino/new-jersey", queryParams: {}},
			// {title: "MI Online Casino", routerLink: "/online-casino/michigan", queryParams: {}},
			{title: "NY Online Casinos", routerLink: "/online-casinos/new-york", queryParams: {}}
		]},
		{title: "Games", subLinks: [
			// {title: "Best Blackjack", routerLink: "/casino-games/blackjack", queryParams: {}},
			// {title: "Best Table Games", routerLink: "/casino-games/table-games", queryParams: {}},
			// {title: "Best Jackpots", routerLink: "/casino-games/jackpots", queryParams: {}},
			// {title: "Best Spins", routerLink: "/casino-games/spins", queryParams: {}},
			// {title: "Best Roulette", routerLink: "/casino-games/roulette", queryParams: {}},
			// {title: "Best Baccarat", routerLink: "/casino-games/baccarat", queryParams: {}},
			{title: "Online Casino Games", routerLink: "/games", queryParams: {}},

		]},
		// {title: "States", routerLink: "/online-casino", queryParams: {}},
		{title: "Apps", subLinks: [
			// {title: "iOS", routerLink: "/online-casino/apps/ios", queryParams: {}},
			// {title: "Android", routerLink: "/online-casino/apps/android", queryParams: {}},
			{title: "Online Casino Apps", routerLink: "/online-casinos/apps", queryParams: {}},
		]},
		{title: "Slots", subLinks: [
			// {title: "Real Money Slots", routerLink: "/slots/real-money", queryParams: {}},
			// {title: "Free Slots", routerLink: "/slots/free", queryParams: {}},
			// {title: "DraftKings Slots", routerLink: "/slots/draftkings", queryParams: {}},
			{title: "Online Slots", routerLink: "/slots", queryParams: {}}
		]}
	] as Array<{
        title: string;
        routerLink?: string;
        queryParams?: Record<string, any>;
        subLinks?: Array<Record<string, any>>;
    }>,
	landingPageSidebar: true,
	bestOffersTitle: "Best Casino Promotions",
	newsletterPromptTitle: "Exclusive Casino Updates",
	newsletterPromptSubtitle: "Get casino offers and news by email.",
	newsletterPromptTermsText: "Unsubscribe anytime. Our <a href='/terms-of-service' target='_blank'>Terms of Service</a> and <a href='/privacy-policy' target='_blank'>Privacy Policy</a> apply.",
    bestBetsFormat: "dimers",
	googleSiteVerificationCode: undefined,
};
