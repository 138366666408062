import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-display-ad',
  templateUrl: './display-ad.component.html',
  styleUrls: ['./display-ad.component.scss']
})
export class DisplayAdComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() type: string;
  @Input() sportCode: string;
  @Input() permanent: boolean;

  adID: string;
  routerWatch: Subscription = null;
  adObject: any = null;
  slot: any = null;
  postInit = false;
  environment = environment;

  constructor(router: Router) {
    // for ad slots which persist through navigations (such as in header and footer)

    // monitor for in-app navigations
    
    if (typeof window !== "undefined" && environment.googleAdsConfig?.adSpaces && environment.googleAdsConfig.customerID) {
      this.routerWatch = router.events.subscribe(e => {
        if (this.permanent) {
          if (e instanceof ActivationEnd) {
    
            // if the new destination is a route type which has a "sport" parameter, use this as the new sport code for targeting, and reload the ad
            if (e.snapshot.paramMap.has("sport") || e.snapshot.children.some(c => c.paramMap.has("sport"))) {
              const newSportCode = e.snapshot.paramMap.get("sport") || e.snapshot.children.find(c => c.paramMap.get("sport")).paramMap.get("sport");
              // (verify that the new one isn't the same as the existing one, if applicable)
              if (this.sportCode?.toLowerCase() !== newSportCode.toLowerCase()) {
                console.log("sport param changed - changing sportCode to " + newSportCode)
                this.sportCode = newSportCode;
                if (this.slot) {
                  this.clearAd();
                }
                this.loadAd();
              }
            }
            // if the new destination doesn't have a "sport" parameter, clear the sport code targeting, and reload the ad (if there was a sport code previously)
            else {
              if (this.sportCode) {
                console.log("sport param not found, wiping sportCode");
                this.sportCode = "";
                if (this.slot) {
                  this.clearAd();
                }
                this.loadAd();
              }
            }
          }
        }
      })
    }
  }

  ngOnInit() {
    if (environment.googleAdsConfig) {
      console.log("app-display-ad ngOnInit " + this.type);
      this.adObject = environment.googleAdsConfig.adSpaces[this.type];
      if (this.adObject) {
        this.adID = this.adObject.id;
      }
    }
    
  }

  ngAfterViewInit() {
    if (environment.googleAdsConfig) {
      console.log("app-display-ad ngAfterViewInit " + this.type);
      if (typeof window !== "undefined" && environment.googleAdsConfig?.adSpaces && environment.googleAdsConfig.customerID) {
        this.loadAd();
        this.postInit = true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (environment.googleAdsConfig) {
      // if sport code changes, reload ad slot
      if (typeof window !== "undefined" && this.postInit && changes.sportCode && changes.sportCode.currentValue?.toLowerCase() !== changes.sportCode.previousValue?.toLowerCase()) {
        if (this.slot) {
          this.clearAd();
        }
        this.loadAd();
      }
    }
  }

  loadAd() {
    if (this.adObject) {
      console.log("loadAd - sportCode = " + this.sportCode);
      (window as any).googletag.cmd.push(() => {
        if (this.type === "main-column") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[320, 50], [728, 90]], this.adObject.id)
              .addService((window as any).googletag.pubads())
              .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
              
            const mainColumnMapping = (window as any).googletag.sizeMapping()
              .addSize([750, 0], [728, 90]) // if device width is larger than 750px, show 728x90 ad
              .addSize([0, 0], [320, 50]) // otherwise show 320x50 ad
              .build();
            this.slot.defineSizeMapping(mainColumnMapping);
        } else if (this.type === "main-column2") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[320, 50], [728, 90]], this.adObject.id)
              .addService((window as any).googletag.pubads())
              .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
              
            const mainColumnMapping = (window as any).googletag.sizeMapping()
              .addSize([750, 0], [728, 90]) // if device width is larger than 750px, show 728x90 ad
              .addSize([0, 0], [320, 50]) // otherwise show 320x50 ad
              .build();
            this.slot.defineSizeMapping(mainColumnMapping);
        } else if (this.type === "main-column3") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[320, 50], [728, 90]], this.adObject.id)
              .addService((window as any).googletag.pubads())
              .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
              
            const mainColumnMapping = (window as any).googletag.sizeMapping()
              .addSize([750, 0], [728, 90]) // if device width is larger than 750px, show 728x90 ad
              .addSize([0, 0], [320, 50]) // otherwise show 320x50 ad
              .build();
            this.slot.defineSizeMapping(mainColumnMapping);
        } else if (this.type === "sidebar-short") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [300, 250], this.adObject.id)
              .addService((window as any).googletag.pubads())
              .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
        } else if (this.type === "sidebar-multi") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[300, 600], [300, 250]], this.adObject.id)
              .addService((window as any).googletag.pubads())
              .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");

            // const sidebarMultiMapping = (window as any).googletag.sizeMapping()
            //   .addSize([750, 0], [300, 600]) // if device width is larger than 750px, show 300x600 ad
            //   .addSize([0, 0], [300, 250]) // otherwise show 300x250 ad
            //   .build();
    
            // this.slot.defineSizeMapping(sidebarMultiMapping);
        } else if (this.type === "header") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[320, 50], [728, 90], [970, 90]], this.adObject.id)
              .addService((window as any).googletag.pubads())
              .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
              
            const fullWidthMapping = (window as any).googletag.sizeMapping()
              .addSize([1000, 0], [970, 90]) // if device width is larger than 1000px, show 970x90 ad
              .addSize([750, 0], [728, 90]) // otherwise if device width is larger than 750px, show 728x90 ad
              .addSize([0, 0], [320, 50]) // otherwise show 320x50 ad
              .build();
              this.slot.defineSizeMapping(fullWidthMapping);
        } else if (this.type === "footer") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[320, 50], [728, 90], [970, 90]], this.adObject.id)
              .addService((window as any).googletag.pubads())
              .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
              
            const fullWidthMapping = (window as any).googletag.sizeMapping()
              .addSize([1000, 0], [970, 90]) // if device width is larger than 1000px, show 970x90 ad
              .addSize([750, 0], [728, 90]) // otherwise if device width is larger than 750px, show 728x90 ad
              .addSize([0, 0], [320, 50]) // otherwise show 320x50 ad
              .build();
            this.slot.defineSizeMapping(fullWidthMapping);
        }

        if (this.slot) {
          (window as any).googletag.display(this.adObject.id);
        }
      });
    }
  }

  ngOnDestroy() {
    if (typeof window !== "undefined" && this.slot) {
      this.clearAd();
    }

    if (this.routerWatch) {
      this.routerWatch.unsubscribe();
    }
  }

  clearAd() {
    (window as any).googletag.cmd.push(() => {
      (window as any).googletag.destroySlots([this.slot]);
    })
  }

}
