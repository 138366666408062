import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map, shareReplay } from "rxjs/operators";
import { BlogArticle } from './blog-article.model';
import { BlogAuthor } from './blog-author.model';
import { BookOffer } from './book-offer.model';
import { compareDesc, formatDistance, parseISO, parseJSON, format } from 'date-fns';
import { SPORTS } from './sports';
import { LocalisationService } from './localisation.service';
import { HoneycombService } from './honeycomb.service';
import { GeneralService } from './general.service';
import { UserLocationData } from "./user-location-data.model";
import { environment } from 'src/environments/environment';
import { Match } from './match.model';
import { LandingPage } from './landing-page.model';

// const api = require("@opentelemetry/api");
// const tracer = require("tracing.ts");

@Injectable({
	providedIn: 'root'
})
export class GamblingTodayBlogService {

	clientCosmicID = "649e2cd2e0bc390008304ba8";
	backupStateID = "646d99f2f16b30000942c9c5";

	adhesiveBannersVisible = true;

	userLocationData$: Observable<UserLocationData> = this.http.get<any>(`${environment.dimersGeoDomain}/v1/get_geolocation`)
		.pipe(
			map((response: any) => {
				if (response) {
					return (response as UserLocationData);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			shareReplay(1),
			catchError(this.handleError<UserLocationData>())
		);


	// TODO
	// getLatestFeaturedGame(): Observable<String> {
	// 	return of("");
	// }


	getBuilderPage(pageCode: string): Observable<LandingPage> {
		console.log('getBuilderPage');
		console.log('getArticle');
		let rno = Math.random()
		console.time('getArticle' + rno)
		const query = {
			"type": "gambling-today-pages",
			"metadata.path": pageCode,
		};
		
		let o: any;
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
			+ `&limit=1&read_key=${this.readKey}`)
		.pipe(
			flatMap((mainResponse: any) => {
				console.log("gotten article id:")
				console.timeLog('getArticle' + rno)
				if (mainResponse.objects && mainResponse.objects.length > 0) {
					o = mainResponse.objects[0];
					const articleID = o.id;
					return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects/${articleID}/revisions?limit=1&sort=created_at&query=%7B"status"%3A"published"%7D&read_key=${this.readKey}`)
				} else if (mainResponse.errors) {
					throw new Error(mainResponse.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			map((revisionsResponse: any) => {
				console.log("gotten article revision:")
				console.timeLog('getArticle' + rno)
				if (revisionsResponse.revisions && revisionsResponse.revisions.length > 0) {
					const earliestPublished = revisionsResponse.revisions[0].created_at;
					{
						return {
							// TODO deal with autocon case
							article_category: "dimers_content" as "dimers_content",
							author: o.metadata.author.title,
							authors: o.metadata.author ? [{
								id: o.metadata.author.id,
								last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
								slug: o.metadata.author.slug,
								first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
								social_username: o.metadata.author.metadata.twitter_username,
								description: o.metadata.author.content,
								short_bio: o.metadata.author.content,
								social_summary_title: o.metadata.author.title + " - Contributor",
								social_summary_description: o.metadata.author.content,
								thumbnail: {
									dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
								},
							}] : [],
							content_description: o.content,
							created_at: earliestPublished,
							featured_article: null,
							title_tag: o.title,
							id: o.id,
							path_url: pageCode,
							published_date: o.published_at,
							published_date_readable: formatDistance(parseJSON(o.published_at), new Date()) + " ago",
							unpublished_at: o.unpublish_at,
							short_title: o.title,
							slug: o.slug,
							main_btn_color: "",
							main_font: "",
							sub_font: "",
							tnc_txt_color: "",
							tnc_btn_size: "",
							publish_status: "",
							is_duplicate: 0,
							is_noindex: false,
							hide_banner: false,
							hide_bonus: false,
							hide_promotion: false,
							socialThumbnail: {
								url: o.metadata.hero_image.url,
							},
							// social_summary_description: o.metadata.preview_text,
							// social_summary_title: o.title + "",
							// sport_betting_category: null,
							// summarized_description: o.metadata.preview_text,
							// tags: o.metadata.tags.map(c => ({
							// 	name: c,
							// 	slug: c,
							// })),
							seo_thumbnail: {
								dynamic_url: o.metadata.hero_image.imgix_url,
								url: o.metadata.hero_image.url,
							},
							title: o.title,
							faqs: [],
							show_faq: false,
							sections: [
								{
									bg_color: "",
									bg_thumbnail: null,
									created_at: "",
									hide_bg_image: false,
									id: 0,
									is_new_tab: false,
									order_index: 0,
									type: "page_heading",
									page_heading: {
										id: 1,
										title: `<h1>${o.title}</h1>`,
										created_at: "",
										props: [] as [],
									}
								},
								o.metadata.hero_image ? {
									bg_color: "",
									bg_thumbnail: null,
									created_at: "",
									hide_bg_image: false,
									id: 1,
									is_new_tab: false,
									order_index: 1,
									type: "page_image_dropper",
									page_image_dropper: {
										thumbnail: {
											url: o.metadata.hero_image.url,
											dynamic_url: o.metadata.hero_image.imgix_url,
										}
									}
								} : null,
								{
									bg_color: "",
									bg_thumbnail: null,
									created_at: "",
									hide_bg_image: false,
									id: 2,
									is_new_tab: false,
									order_index: 2,
									type: "page_body",
									page_body: {
										id: 1,
										description: o.content,
										created_at: "",
										props: [] as [],
									}
								}
							].filter(o => o !== null)
						}
					}
				} else if (revisionsResponse.errors) {
					throw new Error(revisionsResponse.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<LandingPage>())
		)
		
	}

	getStateId(state: string): Observable<string> {
        // TODO
		return of("");
    }

	getBestBooksById(stateId: string): Observable<Record<string, any>> {
        // TODO
		return of({});
    }

    getSportsBooksById(stateId: string): Observable<Array<Record<string, any>>> {
        // TODO
		return of([]);
    }

    getBestBooksPromos(stateId: string): Observable<Array<Record<string, any>>> {
        // TODO
		return of([])
    }

    getSportsBooksReviewMethodology(): Observable<Record<string, any>> {
        // TODO
		return of({});
    }

	getCosmicOffersByStateId(stateId: string) {
		// TODO
		return of([]);
	}

	getSportsbookReview(stateId: string) {
		// TODO
		return of({});
	}

	builderPageExists(pageCode: string): Observable<boolean> {
		console.log('getBuilderPage');
		console.log('getArticle');
		let rno = Math.random()
		console.time('getArticle' + rno)
		const query = {
			"type": "gambling-today-pages",
			"metadata.path": pageCode,
		};
		
		let o: any;
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
			+ `&limit=1&read_key=${this.readKey}`)
		.pipe(
			flatMap((mainResponse: any) => {
				console.log("gotten article id:")
				console.timeLog('getArticle' + rno)
				if (mainResponse.objects && mainResponse.objects.length > 0) {
					o = mainResponse.objects[0];
					const articleID = o.id;
					return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects/${articleID}/revisions?limit=1&sort=created_at&query=%7B"status"%3A"published"%7D&read_key=${this.readKey}`)
				} else if (mainResponse.errors) {
					throw new Error(mainResponse.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			map((revisionsResponse: any) => {
				console.log("gotten article revision:")
				console.timeLog('getArticle' + rno)
				if (revisionsResponse.revisions && revisionsResponse.revisions.length > 0) {
					return true;
				} else if (revisionsResponse.errors) {
					throw new Error(revisionsResponse.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(() => {
				return of(false)
			})
		)
	}

	// TODO
	// getLatestArticleSummariesByTag(tag: string, count: number): Observable<Array<BlogArticle>> {
	// 	return of([]);
	// }




	getAdhesiveBanner(stateCode: string): Observable<Record<string, any>> {
		console.log('getAdhesiveBanner');
		// TODO
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/banners?state=${stateCode}`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data && response.data.length > 0) {
		// 			return (response.data[0]);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<Record<string, any>>())
		// )
		return of(null);
	}

	// getLatestArticleCategories(): Observable<Array<string>> {
	// 	return this.http.get<any>(https://api.cosmicjs.com/v1/insiderapi/objects?type=stats-insider-articles&sort=-modified_at&limit=1&props=metafields")
	// 	.pipe(
	// 		map((response: any) => {
	// 			if (response && response.objects && response.objects[0]
	// 				&& response.objects[0].metafields && response.objects[0].metafields.some(m => m.key === "tags")) {
	// 				return response.objects[0].metafields.find(m => m.key === "tags").options.map(o => o.value);
	// 			} else if (response.errors) {
	// 				throw new Error(response.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
	// 		catchError(this.handleError<Array<Record<string, any>>>())
	// 	)
	// }

	getStateID(stateCode: string): Observable<string> {
		const stateQuery = {
			"type": "regions",
			"slug": stateCode.toLowerCase().replace(/ /g, '-')
		};

		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `query=${JSON.stringify(stateQuery).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
			+ `&limit=1&read_key=${this.readKey}`)
		.pipe(
			map((regionResponse: any) => {
				if (regionResponse.objects && regionResponse.objects.length > 0) {
					return regionResponse.objects[0].id;
				} else if (regionResponse.errors) {
					throw new Error(regionResponse.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),

			catchError(() => {
				return of(this.backupStateID);
			})
		)
	}

	getWelcomeOffers(state: string, count?: number, bookmakerID?: number): Observable<Array<BookOffer>> {
		console.log('getWelcomeOffers');
		
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers/welcome-promo?${count ? `limit=${count}&` : ``}state=${state}${bookmakerID? `&filter[onlySportsBook]=${bookmakerID}` : ""}`)
		// 	.pipe(
		// 		map((response: any) => {
		// 			if (response.data) {
		// 				return (response.data as Array<BookOffer>);
		// 			} else if (response.errors) {
		// 				throw new Error(response.errors[0].message)
		// 			} else {
		// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 			}
		// 		}),
		// 		catchError(this.handleError<Array<BookOffer>>())
		// 	)


		return this.getStateID(state)
		.pipe(
			flatMap((stateID: string) => {
				const query = {
					"type": "sportsbook-promos",
					"metadata.atad_clients": this.clientCosmicID,
					"metadata.sportsbook": bookmakerID || undefined,
					"metadata.state": stateID,
					"metadata.placement_locations": "Sidebar Welcome Offers"
				}

				return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
					+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
					+ `&limit=${count}&read_key=${this.readKey}`)
			}),

			map((response: any) => {
				if (response.objects) {
					// return (response.data as Array<BlogArticle>)
					// 	.sort((a,b) => compareDesc(
					// 		parseJSON(a.published_date),
					// 		parseJSON(b.published_date)
					// 	));

					const transformedPromos = response.objects
						.map(o => {
								
							return {
								"id": o.id,
								"header": o.metadata.promos_title,
								"sub_header": o.metadata.promos_subtitle,
								"affiliate_link": o.metadata.promos_link,
								"sort_order": null,
								"best_promo_flag": 0,
								"best_promo_position": 0,
								"welcome_promo_flag": 0,
								"welcome_promo_position": 0,
								"welcome_condition_text": null,
								"bookie_review_flag": false,
								"disclaimer_text": o.metadata.promos_disclaimer,
								"bookmaker_id": o.metadata.sportsbook[0].id,
								"created_at": null,
								"deleted_at": null,
								"button_text": o.metadata.promos_button_text,
								"image_alt_text": null,
								"offer_value": o.metadata.promos_value,
								"tags": [],
								"promo_logo": {
									"url": o.metadata.promo_image.url,
									"dynamic_url": o.metadata.promo_image.imgix_url,
									"custom_properties": {
										alt_text: ""
									}
								},
								"promo_matches": [],
								"states": o.metadata.state.map(s => ({
									"id": s.id,
									"name": s.title,
									"abbreviation": s.metadata.abbreviation,
									"order_index": s.metadata.order_index,
									"created_at": s.created_at,
									"updated_at": s.modified_at
								})),
								"bookmaker": {
									"id": o.metadata.sportsbook[0].id,
									"slug": o.metadata.sportsbook[0].slug,
									"name": o.metadata.sportsbook[0].title,
									"hex_color": o.metadata.sportsbook[0].metadata.hex_color,
									"cta_btn_text": null,
									"publish_status": null,
									"disclaimer_text": null,
									"published_at": null,
									"logo": undefined,
									"welcome_promo_logo": {
										"url": o.metadata.sportsbook[0].metadata.promo_logo.url,
										"dynamic_url": o.metadata.sportsbook[0].metadata.promo_logo.imgix_url,
										"custom_properties": {
											alt_text: o.metadata.sportsbook[0].title
										}
									},
									"small_logo": undefined,
									"banner_logo": undefined,
									"welcome_logo": undefined,
									"states": o.metadata.sportsbook[0].metadata.states?.map(s => ({
										"id": s.id,
										"name": s.title,
										"abbreviation": s.metadata.abbreviation,
										"order_index": s.metadata.order_index,
										"created_at": s.created_at,
										"updated_at": s.modified_at
									})) || []
									
								}
							}
						});
					
					return transformedPromos;
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			
			
			catchError(this.handleError<Array<BookOffer>>())
		)
	}

	getBestOffers(count: number, state: string, sportCode?: string): Observable<Array<BookOffer>> {
		console.log('getBestOffers');
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers/best-promo?limit=${count}&state=${state}${
		// 	sportCode ? `&sport_league=${sportCode.toUpperCase()
		// }` : ""}`)
		// 	.pipe(
		// 		map((response: any) => {
		// 			if (response.data) {
		// 				return (response.data as Array<BookOffer>);
		// 			} else if (response.errors) {
		// 				throw new Error(response.errors[0].message)
		// 			} else {
		// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 			}
		// 		}),
		// 		catchError(this.handleError<Array<BookOffer>>())
		// 	)
		// TODO
		return this.getStateID(state)
		.pipe(
			flatMap((stateID: string) => {
				const query = {
					"type": "sportsbook-promos",
					"metadata.atad_clients": this.clientCosmicID,
					"metadata.state": stateID,
					"metadata.placement_locations": "Best Offers"
				}
				return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
					+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
					+ `&limit=${count}&read_key=${this.readKey}`)
			}),

			map((response: any) => {
				if (response.objects) {
					// return (response.data as Array<BlogArticle>)
					// 	.sort((a,b) => compareDesc(
					// 		parseJSON(a.published_date),
					// 		parseJSON(b.published_date)
					// 	));

					const transformedPromos = response.objects
						.map(o => {
								
							return {
								"id": o.id,
								"header": o.metadata.promos_title,
								"sub_header": o.metadata.promos_subtitle,
								"affiliate_link": o.metadata.promos_link,
								"sort_order": null,
								"best_promo_flag": 0,
								"best_promo_position": 0,
								"welcome_promo_flag": 0,
								"welcome_promo_position": 0,
								"welcome_condition_text": null,
								"bookie_review_flag": false,
								"disclaimer_text": o.metadata.promos_disclaimer,
								"bookmaker_id": o.metadata.sportsbook[0].id,
								"created_at": null,
								"deleted_at": null,
								"button_text": o.metadata.promos_button_text,
								"image_alt_text": null,
								"offer_value": o.metadata.promos_value,
								"tags": [],
								"promo_logo": {
									"url": o.metadata.promo_image.url,
									"dynamic_url": o.metadata.promo_image.imgix_url,
									"custom_properties": {
										alt_text: ""
									}
								},
								"promo_matches": [],
								"states": o.metadata.state.map(s => ({
									"id": s.id,
									"name": s.title,
									"abbreviation": s.metadata.abbreviation,
									"order_index": s.metadata.order_index,
									"created_at": s.created_at,
									"updated_at": s.modified_at
								})),
								"bookmaker": {
									"id": o.metadata.sportsbook[0].id,
									"slug": o.metadata.sportsbook[0].slug,
									"name": o.metadata.sportsbook[0].title,
									"hex_color": o.metadata.sportsbook[0].metadata.hex_color,
									"cta_btn_text": null,
									"publish_status": null,
									"disclaimer_text": null,
									"published_at": null,
									"logo": undefined,
									"welcome_promo_logo": {
										"url": o.metadata.sportsbook[0].metadata.promo_logo.url,
										"dynamic_url": o.metadata.sportsbook[0].metadata.promo_logo.imgix_url,
										"custom_properties": {
											alt_text: o.metadata.sportsbook[0].title
										}
									},
									"small_logo": undefined,
									"banner_logo": undefined,
									"welcome_logo": undefined,
									"states": o.metadata.sportsbook[0].metadata.states?.map(s => ({
										"id": s.id,
										"name": s.title,
										"abbreviation": s.metadata.abbreviation,
										"order_index": s.metadata.order_index,
										"created_at": s.created_at,
										"updated_at": s.modified_at
									})) || []
									
								}
							}
						});
					
					return transformedPromos;
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			
			
			catchError(this.handleError<Array<BookOffer>>())
		)
	}

	getUserLocationData(): Observable<UserLocationData> {
		console.log('getting geo data getUserLocationData()');
		return this.http.get<any>(`${environment.dimersGeoDomain}/v1/get_geolocation`)
			.pipe(
				map((response: any) => {
					if (response.data) {
						return (response.data as UserLocationData);
					} else if (response.errors) {
						throw new Error(response.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				catchError(this.handleError<UserLocationData>())
			)
	}

	getNewsMeta(sportCode: string): Observable<Record<string, any>> {
		console.log('getNewsMeta');
		if (sportCode === "All") {
			return this.getAppSettings("news");
		}
		// TODO
		return of({});
	}

	// this is for sport-specific version of best props page 
	getSportBestPropsMeta(sportCode: string): Observable<Record<string, any>> {
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/app-best-props?filter[search]=${sportCode}`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data && response.data.length > 0) {
		// 			return (response.data[0]);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<Record<string, any>>())
		// )
		// TODO
		return of({});
	}



	getStateSettings(stateCode?: string): Observable<Array<Record<string, any>>> {
		console.log('getStateSettings');
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/state-settings?${stateCode ? `filter[onlyState]=${stateCode}` : `limit=all&sort=custom_order_index`}`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data) {
		// 			return (response.data);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<Record<string, any>>())
		// )
		return of([]);
	}

	getBestBooksData(stateCode?: string): Observable<Array<Record<string, any>>> {
		// console.log('getBestBooksData');
		// return this.http.get<any>(stateCode ? `${environment.dimersApiDomain}/api/v1/bookmakers/location-order?state=${stateCode}` : `${environment.dimersApiDomain}/api/v1/bookmakers`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data) {
		// 			return (response.data);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<Record<string, any>>())
		// )
		// TODO
		return of([]);
	}

	getBookmakerData(bookmakerCode: string, stateCode?: string): Observable<Record<string, any>> {
		// console.log('getBookmakerData');
		// return this.http.get<any>(stateCode ? `${environment.dimersApiDomain}/api/v1/bookmakers/${bookmakerCode}?state=${stateCode}` : `${environment.dimersApiDomain}/api/v1/bookmakers/${bookmakerCode}`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data) {
		// 			return (response.data);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<Record<string, any>>())
		// )
		return of([]);
	}

	getBookmakerPromo(id: string): Observable<Record<string, any>> {
		console.log('getBookmakerPromo');
		// TODO
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers/promo?filter[id]=${id}`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data && response.data.length > 0) {
		// 			return (response.data[0]);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<Record<string, any>>())
		// )
		return of(null);
	}

	getHyperlink(id: string): Observable<Record<string, any>> {
		// console.log('getHyperlink');
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/hyperlinks/${id}`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data) {
		// 			return (response.data);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<Record<string, any>>())
		// )
		// TODO
		return of({});
	}


	addNewsletterEmail(email: string, firstName?: string): Observable<boolean> {
		console.log('addNewsletterEmail()');
		return this.http.post(`https://api.statsinsider.com.au/v1/visionsix/777013`, {
			email: email,
			first_name: firstName,
		}, {
			headers: {
				"Authorization": "Bearer gU4De3u4bNPyXXLfAWCm_X-xk6NDrR93lrSGgdb1zNsnjN7FmNfthNrGXg8oY9ho8LZD0eVPqiS2QpKm"
			}
		})
		.pipe(
			map((response: any) => {
				if (response.subscribed?.email) {
					return true;
				} else if (response.errors) {	
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<boolean>())
		)
	}

	getBettingExplainedCategories(): Observable<Array<Record<string, any>>> {
		// console.log('getBettingExplainedCategories()');
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/sport-bettings/category`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data) {
		// 			return (response.data);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<Array<Record<string, any>>>())
		// )
		// TODO
		return of([]);
	}

	getBettingExplainedCategory(slug: string): Observable<Record<string, any>> {
		// console.log('getBettingExplainedCategory()');
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/sport-bettings/category/${slug}`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data) {
		// 			return (response.data);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<Record<string, any>>())
		// )
		// TODO
		return of({});
	}

	getBettingExplainedArticle(slug: string): Observable<BlogArticle> {
		// console.log('getBettingExplainedArticle()');
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/sport-bettings/${slug}`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data) {
		// 			return (response.data);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<BlogArticle>())
		// )
		return of(null);

	}

	getOnboardingBookmakers(): Observable<Array<Record<string, any>>> {
		console.log('getOnboardingBookmakers()');
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers?filter[is_onboarding]=true&sort=onboarding_sort_index&limit=all`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data) {
		// 			return (response.data);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<Array<Record<string, any>>>())
		// )
		return of([]);
	}

	searchBettingExplainedArticles(searchTerm: string): Observable<Array<BlogArticle>> {
		console.log('searchBettingExplainedArticles()');
		// TODO
		return of([]);
	}

	sessionIncrementer = 1;

	getIncrement(): number {
		console.log('getIncrement()');
		this.sessionIncrementer++;
		return this.sessionIncrementer;
	}

	getSportsBettingPoll(id: string): Observable<Record<string, any>> {
		console.log('getSportsBettingPoll()');
		// TODO
		return of({});
	}

	getResponsiveBanner(id: number): Observable<Record<string, any>> {
		console.log('getResponsiveBanner()');
		// TODO
		// return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/responsive-banners/${id}`)
		// .pipe(
		// 	map((response: any) => {
		// 		if (response.data) {
		// 			return (response.data);
		// 		} else if (response.errors) {
		// 			throw new Error(response.errors[0].message)
		// 		} else {
		// 			throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
		// 		}
		// 	}),
		// 	catchError(this.handleError<Record<string, any>>())
		// )
		return of(null);
	}



	// timeAgoString(date: Date): string {
	// 	let hoursago = differenceInMilliseconds(date, new Date()) / (1000 * 3600) * -1;
	// 	if (hoursago < 1) {
	// 		// minutes ago
	// 		var minutesago = hoursago * 60;
	// 		return minutesago.toFixed(0) + (minutesago.toFixed(0) == "1" ? " minute ago" : " minutes ago");
	// 	} else if (hoursago < 24) {
	// 		// hours ago
	// 		return hoursago.toFixed(0) + (hoursago.toFixed(0) == "1" ? " hour ago" : " hours ago");
	// 	} else if (hoursago < (24 * 7)) {
	// 		// days ago
	// 		var daysago = hoursago / 24;
	// 		return daysago.toFixed(0) + (daysago.toFixed(0) == "1" ? " day ago" : " days ago");
	// 	} else if (isThisYear(date)) {
	// 		return format(date, "d MMMM")
	// 	} else {
	// 		return format(date, "d MMMM yyyy")
	// 	}
	// }

	convertCategorySlug(categorySlug: string): string {
		if (this.localisationService.sportPrioritySort(SPORTS).some(s => s.code === categorySlug.toUpperCase())) {
			return SPORTS.find(s => s.code === categorySlug.toUpperCase()).shortName;
		}

		return categorySlug.toUpperCase();
	}

	

	private handleError<T>() {
		return (error: any): Observable<T> => {
			return throwError(new Error("CMS_CALL_FAILED " + JSON.stringify(error, ["message", "arguments", "type", "name"])));
		}
	}

	readKey = "5px9a31VWUleoToX0AH7P0W3DasfsBBNO4qgYoblJCwNlbiSYI";

	constructor(
		private http: HttpClient,
		private localisationService: LocalisationService,
		private honeycomb: HoneycombService,
		private generalService: GeneralService,
	) { }

	disableAdhesiveBanners(): void {
		this.adhesiveBannersVisible = false;
	}

	getArticle(slug: string, revisionID?: string): Observable<BlogArticle> {
		console.log('getArticle');
		let rno = Math.random()
		console.time('getArticle' + rno)
		const query = {
			"type": "gambling-today-articles",
			"slug": slug,
		};
		if (revisionID) {
			return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
				+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&limit=1&props=id`
				+ `&read_key=${this.readKey}&status=any`)
			.pipe(
				flatMap((response1: any) => {
					console.log("gotten article:")
					console.timeLog('getArticle' + rno)
					if (response1.objects && response1.objects.length > 0) {
						const articleID = response1.objects[0].id;
						return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects/${articleID}/revisions/${revisionID}`
							+ `?read_key=${this.readKey}`)
					} else if (response1.errors) {
						throw new Error(response1.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				map((response2: any) => {
					if (response2.revision) {
						console.log("gotten revision:");
						console.timeLog('getArticle' + rno)
						const o = response2.revision;
						return {
							// TODO deal with autocon case
							article_category: "dimers_content" as "dimers_content",
							author: o.metadata.author.title,
							authors: [{
								id: o.metadata.author.id,
								last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
								slug: o.metadata.author.slug,
								first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
								social_username: o.metadata.author.metadata.twitter_username,
								description: o.metadata.author.content,
								short_bio: o.metadata.author.content,
								social_summary_title: o.metadata.author.title + " - Contributor",
								social_summary_description: o.metadata.author.content,
								thumbnail: {
									dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
								},
							}],
							content_description: o.content,
							created_at: o.created_at,
							featured_article: null,
							id: o.id,
							published_date: null,
							published_date_readable: "Unpublished",
							unpublished_at: o.unpublish_at,
							short_title: o.title,
							slug: o.slug,
							socialThumbnail: {
								url: o.metadata.hero_image.url,
							},
							social_summary_description: o.metadata.preview_text,
							social_summary_title: o.title + "",
							sport_betting_category: null,
							summarized_description: o.metadata.preview_text,
							tags: o.metadata.tags.map(c => ({
								name: c,
								slug: c,
							})),
							thumbnail: {
								dynamic_url: o.metadata.hero_image.imgix_url,
								url: o.metadata.hero_image.url,
							},
							title: o.title,
							faqs: [],
							show_faq: false,
						};
					} else if (response2.errors) {
						throw new Error(response2.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				catchError(this.handleError<BlogArticle>())
			)
		} else {
			let o: any;
			return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
				+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
				+ `&limit=1&read_key=${this.readKey}`)
			.pipe(
				flatMap((mainResponse: any) => {
					console.log("gotten article id:")
					console.timeLog('getArticle' + rno)
					if (mainResponse.objects && mainResponse.objects.length > 0) {
						o = mainResponse.objects[0];
						const articleID = o.id;
						return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects/${articleID}/revisions?limit=1&sort=created_at&query=%7B"status"%3A"published"%7D&read_key=${this.readKey}`)
					} else if (mainResponse.errors) {
						throw new Error(mainResponse.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				map((revisionsResponse: any) => {
					console.log("gotten article revision:")
					console.timeLog('getArticle' + rno)
					if (revisionsResponse.revisions && revisionsResponse.revisions.length > 0) {
						const earliestPublished = revisionsResponse.revisions[0].created_at;
						{
							return {
								// TODO deal with autocon case
								article_category: "dimers_content" as "dimers_content",
								author: o.metadata.author.title,
								authors: [{
									id: o.metadata.author.id,
									last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
									slug: o.metadata.author.slug,
									first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
									social_username: o.metadata.author.metadata.twitter_username,
									description: o.metadata.author.content,
									short_bio: o.metadata.author.content,
									social_summary_title: o.metadata.author.title + " - Contributor",
									social_summary_description: o.metadata.author.content,
									thumbnail: {
										dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
									},
								}],
								content_description: o.content,
								created_at: earliestPublished,
								featured_article: null,
								id: o.id,
								published_date: o.published_at,
								published_date_readable: formatDistance(parseJSON(o.published_at), new Date()) + " ago",
								unpublished_at: o.unpublish_at,
								short_title: o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.title + "",
								sport_betting_category: null,
								summarized_description: o.metadata.preview_text,
								tags: o.metadata.tags.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
								},
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						}
					} else if (revisionsResponse.errors) {
						throw new Error(revisionsResponse.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				catchError(this.handleError<BlogArticle>())
			)
		}
	}

	getLatestArticleSummaries(count: number): Observable<Array<BlogArticle>> {
		console.log('getLatestArticleSummaries');
		
		let rno = Math.random()
		console.time('getLatestArticleSummaries ' + rno)
		const query = {
			"type": "gambling-today-articles",
		}
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
			+ `&limit=${count}&read_key=${this.readKey}&sort=-modified_at`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					// return (response.data as Array<BlogArticle>)
					// 	.sort((a,b) => compareDesc(
					// 		parseJSON(a.published_date),
					// 		parseJSON(b.published_date)
					// 	));
					console.log("latest article summaries retrieved:")
					console.timeLog('getLatestArticleSummaries '+rno)

					const articles = response.objects
						.sort((a,b) => compareDesc(parseISO(a.published_at), parseISO(b.published_at)));

					console.log("latest article summaries sorted:")
					console.timeLog('getLatestArticleSummaries '+rno)
					const transformedArticles = articles
						.map(o => {
								
							// let combinedDate;
							// let postDate = parseISO(o.metadata.originally_published);
							let timeDate = parseJSON(o.published_at);
							// combinedDate = new Date(postDate.getFullYear(), postDate.getMonth(), postDate.getDate(),
							// 	timeDate.getHours(), timeDate.getMinutes(), timeDate.getSeconds());
							return {
								// TODO deal with autocon case
								article_category: "dimers_content",
								author: o.metadata.author.title,
								authors: [{
									id: o.metadata.author.id,
									last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
									slug: o.metadata.author.slug,
									first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
									social_username: o.metadata.author.metadata.twitter_username,
									description: o.metadata.author.content,
									short_bio: o.metadata.author.content,
									social_summary_title: o.metadata.author.title + " - Contributor",
									social_summary_description: o.metadata.author.content,
									thumbnail: {
										dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
									},
								}],
								content_description: o.content,
								created_at: o.created_at,
								featured_article: null,
								id: o.id,
								published_date: timeDate.toJSON(),
								published_date_readable: formatDistance(timeDate, new Date()) + " ago",
								unpublished_at: o.unpublish_at,
								short_title: o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.title + "",
								sport_betting_category: [],
								summarized_description: o.metadata.preview_text,
								tags: o.metadata.tags.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
								},
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						});
					
					console.log("latest article summaries transformed:")
					console.timeLog('getLatestArticleSummaries '+rno);
					return transformedArticles;
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
	}
    
    //excludeFeature parameter only used for SI(for horse radcing home page use only)
	getLatestArticleSummariesByCategory(category: string, count: number, exceptID?: number, excludeFeature?: boolean): Observable<Array<BlogArticle>> {
		console.log('getLatestArticleSummariesByCategory');
		const query = {
			"type": "gambling-today-articles",
			"metadata.tags": category,
		}
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
			+ `&limit=${count}&read_key=${this.readKey}`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					// return (response.data as Array<BlogArticle>)
					// 	.sort((a,b) => compareDesc(
					// 		parseJSON(a.published_date),
					// 		parseJSON(b.published_date)
					// 	));
					return response.objects
						.sort((a,b) => compareDesc(parseISO(a.published_at), parseISO(b.published_at)))
						.map(o => {
								
							// let combinedDate;
							// let postDate = parseISO(o.metadata.originally_published);
							let timeDate = parseJSON(o.published_at);
							// combinedDate = new Date(postDate.getFullYear(), postDate.getMonth(), postDate.getDate(),
							// 	timeDate.getHours(), timeDate.getMinutes(), timeDate.getSeconds());
							return {
								// TODO deal with autocon case
								article_category: "dimers_content",
								author: o.metadata.author.title,
								authors: [{
									id: o.metadata.author.id,
									last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
									slug: o.metadata.author.slug,
									first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
									social_username: o.metadata.author.metadata.twitter_username,
									description: o.metadata.author.content,
									short_bio: o.metadata.author.content,
									social_summary_title: o.metadata.author.title + " - Contributor",
									social_summary_description: o.metadata.author.content,
									thumbnail: {
										dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
									},
								}],
								content_description: o.content,
								created_at: o.created_at,
								featured_article: null,
								id: o.id,
								published_date: timeDate.toJSON(),
								published_date_readable: formatDistance(timeDate, new Date()) + " ago",
								unpublished_at: o.unpublish_at,
								short_title: o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.title + "",
								sport_betting_category: [],
								summarized_description: o.metadata.preview_text,
								tags: o.metadata.tags.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
								},
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						});
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
	}

	getFeaturedArticleSummaries(count: number, category?: string): Observable<Array<BlogArticle>> {
		// console.log('getFeaturedArticleSummaries');
		let rno = Math.random()
		// console.time('getFeaturedArticleSummaries ' + rno)
		const query = {
			"type": "gambling-today-articles",
			"metadata.nav_featured": "Yes",
			"metadata.tags": category ? category.toUpperCase() : undefined,
		}
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&props=title,slug,published_at,metadata`
			+ `&limit=${count}&read_key=${this.readKey}`)
		
		.pipe(
			map((response: any) => {
				// console.log(response)

				// let span = tracer.startSpan("expensive-query");

				if (response.objects) {
					this.honeycomb.sendMessage({
						message: `finished retrieval from blog service`
					});
			
					console.log("featured article summaries retrieved:")
					// console.timeLog('getFeaturedArticleSummaries '+rno)

					const articles = response.objects
						// .sort((a,b) => compareDesc(parseISO(a.published_at), parseISO(b.published_at)));

					console.log("featured article summaries sorted:")
					// console.timeLog('getFeaturedArticleSummaries '+rno)
					const transformedArticles = articles
						.map(o => {
								
							// let combinedDate;
							// let postDate = parseISO(o.metadata.originally_published);
							let timeDate = parseJSON(o.published_at);
							// combinedDate = new Date(postDate.getFullYear(), postDate.getMonth(), postDate.getDate(),
							// 	timeDate.getHours(), timeDate.getMinutes(), timeDate.getSeconds());
							return {
								// TODO deal with autocon case
								article_category: "dimers_content",
								author: o.metadata.author.title,
								authors: [{
									id: o.metadata.author.id,
									last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
									slug: o.metadata.author.slug,
									first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
									social_username: o.metadata.author.metadata.twitter_username,
									description: o.metadata.author.content,
									short_bio: o.metadata.author.content,
									social_summary_title: o.metadata.author.title + " - Contributor",
									social_summary_description: o.metadata.author.content,
									thumbnail: {
										dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
									},
								}],
								content_description: o.content,
								created_at: o.created_at,
								featured_article: null,
								id: o.id,
								published_date: timeDate.toJSON(),
								published_date_readable: formatDistance(timeDate, new Date()) + " ago",
								unpublished_at: o.unpublish_at,
								short_title: o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.title + "",
								sport_betting_category: [],
								summarized_description: o.metadata.preview_text,
								tags: o.metadata.tags.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
								},
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						}) as Array<BlogArticle>;
					
					console.log("featured article summaries converted:")
					// console.timeLog('getFeaturedArticleSummaries '+rno);
					return transformedArticles;
					// .sort((a,b) => a.featured_article?.order_index - b.featured_article?.order_index);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
	}

	getAuthors(): Observable<Array<BlogAuthor>> {
		console.log('getAuthors');
		const query = {
			"type": "gambling-today-authors",
		}
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
			+ `&read_key=${this.readKey}&props=id,title,slug,content,metadata`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					return response.objects.map((o: Record<string, any>) => ({
						id: o.id,
						last_name: (o.metadata.author_first_name && o.title.includes(o.metadata.author_first_name)) ? o.title.replace(o.metadata.author_first_name, "").trim() : o.title,
							slug: o.slug,
							first_name: (o.metadata.author_first_name && o.title.includes(o.metadata.author_first_name)) ? o.metadata.author_first_name : "",
							social_username: o.metadata.twitter_username,
						description: o.content,
						short_bio: o.content,
						social_summary_title: o.metadata.author.title + " - Contributor",
						featured_candidate: o.metadata.featured_candidate.includes("Yes"),
						social_summary_description: o.metadata.author.content,
						thumbnail: {
							dynamic_url: o.metadata.author_thumbnail.imgix_url,
						},
					}));
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogAuthor>>())
		)
	}

	getAuthor(slug: string): Observable<BlogAuthor> {
		console.log('getAuthor');
		const query = {
			"type": "gambling-today-authors",
			"slug": slug,
		}
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?query=%7B%22type%22%3A%22gambling-today-authors%22%2C%22slug%22%3A%22${slug}%22%7D`
			+ `&read_key=${this.readKey}&props=id,slug,title,content,metadata`)
		.pipe(
			map((response: any) => {
				if (response.objects?.length > 0) {
					let author = response.objects[0];
					return {
						id: author.id,
						last_name: (author.metadata.author_first_name && author.title.includes(author.metadata.author_first_name)) ? author.title.replace(author.metadata.author_first_name, "").trim() : author.title,
						slug: author.slug,
						first_name: (author.metadata.author_first_name && author.title.includes(author.metadata.author_first_name)) ? author.metadata.author_first_name : "",
						social_username: author.metadata.twitter_username,
						description: author.content,
						short_bio: author.content,
						social_summary_title: author.title + " - Contributor",
						social_summary_description: author.content,
						thumbnail: {
							dynamic_url: author.metadata.author_thumbnail.imgix_url,
						},
					};
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<BlogAuthor>())
		)
	}

	getArticleSummariesPage(authorID: string, category: string, query: string, skip: number = 0, count: number): Observable<{articles: Array<BlogArticle>, totalCount: number}> {
		const queryObject = {
			"type": "gambling-today-articles",
			"metadata.author": authorID ? authorID : undefined,
			"metadata.tags": category ? category.toUpperCase() : undefined,
			"$or": query ? [
				{"title": {"$regex": query, "$options": "i"}},
				{"content": {"$regex": query, "$options": "i"}}
			] : undefined,
		}
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?`
			+ `limit=${count}&query=${JSON.stringify(queryObject).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&props=title,slug,published_at,metadata`
			+ `&read_key=${this.readKey}&sort=-modified_at&skip=${skip}`)
		.pipe(
			map((response: any) => {
				if (response && response.objects && response.total) {
					return {articles: (response.objects.map(o => {
							
						// let combinedDate;
						// let postDate = parseISO(o.metadata.originally_published);
						let timeDate = parseJSON(o.published_at);
						// combinedDate = new Date(postDate.getFullYear(), postDate.getMonth(), postDate.getDate(),
						// 	timeDate.getHours(), timeDate.getMinutes(), timeDate.getSeconds());
						return {
							// TODO deal with autocon case
							article_category: "dimers_content",
							author: o.metadata.author.title,
							authors: [{
								id: o.metadata.author.id,
								last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
								slug: o.metadata.author.slug,
								first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
								social_username: o.metadata.author.metadata.twitter_username,
								description: o.metadata.author.content,
								short_bio: o.metadata.author.content,
								social_summary_title: o.metadata.author.title + " - Contributor",
								social_summary_description: o.metadata.author.content,
								thumbnail: {
									dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
								},
							}],
							content_description: o.content,
							created_at: o.created_at,
							featured_article: null,
							id: o.id,
							published_date: timeDate.toJSON(),
							published_date_readable: formatDistance(timeDate, new Date()) + " ago",
							unpublished_at: o.unpublish_at,
							short_title: o.title,
							slug: o.slug,
							socialThumbnail: {
								url: o.metadata.hero_image.url,
							},
							social_summary_description: o.metadata.preview_text,
							social_summary_title: o.title + "",
							sport_betting_category: null,
							summarized_description: o.metadata.preview_text,
							tags: o.metadata.tags.map(c => ({
								name: c,
								slug: c,
							})),
							thumbnail: {
								dynamic_url: o.metadata.hero_image.imgix_url,
								url: o.metadata.hero_image.url,
							},
							title: o.title,
							faqs: [],
							show_faq: false,
						}
					}) as Array<BlogArticle>), totalCount: (response.total as number)};
				} else if (response && (response.objects === null || !response.total)) {
					return {articles: [], totalCount: 0};
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<{articles: Array<BlogArticle>, totalCount: number}>())
		)
	}

	getAuthorSummariesPage(authorSlug: string, skip: number, count: number) {
		return of([]);
	}

	// getLatestArticleCategories(): Observable<Array<string>> {
	// 	return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?query=%7B%22type%22%3A%22gambling-today-articles%22%7D&limit=1&read_key=${this.readKey}&props=metafields`)
	// 	.pipe(
	// 		map((response: any) => {
	// 			if (response && response.objects && response.objects[0]
	// 				&& response.objects[0].metafields && response.objects[0].metafields.some(m => m.key === "tags")) {
	// 				return response.objects[0].metafields.find(m => m.key === "tags").options.map(o => o.value);
	// 			} else if (response.errors) {
	// 				throw new Error(response.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
	// 		catchError(this.handleError<Array<Record<string, any>>>())
	// 	)
	// }

	getAppSettings(pageCode: string): Observable<Record<string, any>> {
		let rno = Math.random()
		console.log('getAppSettings');
		console.time('getAppSettings' + rno);
		return this.http.get<any>(`https://api.cosmicjs.com/v2/buckets/insiderapi/objects?query=%7B%22type%22%3A%22page-meta-objects%22%2C%22slug%22%3A%22${"gt-" + pageCode}%22%7D&read_key=${this.readKey}&props=slug,title,content,metadata`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					console.log("Page meta returned:")
					console.timeLog('getAppSettings' + rno)
					const returnedObject = {
						...response.objects[0],
						...response.objects[0].metadata,
						app_title: response.objects[0].title,
						page_title: response.objects[0].title,
						thumbnail: {
							url: response.objects[0].metadata?.meta_image?.url || undefined,
						}
					};
					console.log("Page meta converted:");
					console.timeLog('getAppSettings' + rno)
					return returnedObject;
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getBetHubSettings(sportCode: string): Observable<Record<string, any>> {
		// TODO
		return of({});
	}

	getMatchMeta(matchID: string): Observable<Record<string, any>> {
		// TODO
		return of({});
	}

	// TODO
	getBestOddsMeta(): Observable<Array<Record<string, any>>> {
		console.log('getBestOddsMeta');
		return of([]);
	}

	getFuturesMeta(sportCode: string): Observable<Record<string, any>> {
		// TODO
		return of({});
	}

	
	// this is for sport-specific version of best bets page
	// TODO
	getSportBestBetsMeta(sportCode: string): Observable<Record<string, any>> {
		return of({});
	}

	// TODO
	getPodcastInformation(): Observable<Record<string, any>> {
		console.log('getPodcastInformation');
		return of({});
	}

	// TODO
	getPodcastList(): Observable<Array<Record<string, any>>> {
		console.log('getPodcastList')
		return of([])
	}

	
	// TODO
	getFreeToPlayData(): Observable<Record<string, any>> {
		console.log('getFreeToPlayData');
		return of({});
	}

	// TODO
	getMatchPagePromos(matchID: string): Observable<Array<Record<string, any>>> {
		console.log('getMatchPagePromos()');
		return of([]);
	}



	// timeAgoString(date: Date): string {
	// 	let hoursago = differenceInMilliseconds(date, new Date()) / (1000 * 3600) * -1;
	// 	if (hoursago < 1) {
	// 		// minutes ago
	// 		var minutesago = hoursago * 60;
	// 		return minutesago.toFixed(0) + (minutesago.toFixed(0) == "1" ? " minute ago" : " minutes ago");
	// 	} else if (hoursago < 24) {
	// 		// hours ago
	// 		return hoursago.toFixed(0) + (hoursago.toFixed(0) == "1" ? " hour ago" : " hours ago");
	// 	} else if (hoursago < (24 * 7)) {
	// 		// days ago
	// 		var daysago = hoursago / 24;
	// 		return daysago.toFixed(0) + (daysago.toFixed(0) == "1" ? " day ago" : " days ago");
	// 	} else if (isThisYear(date)) {
	// 		return format(date, "d MMMM")
	// 	} else {
	// 		return format(date, "d MMMM yyyy")
	// 	}
	// }

}
