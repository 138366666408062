import { Injectable } from '@angular/core';

import Libhoney from "libhoney";
@Injectable({
  providedIn: 'root'
})
export class HoneycombService {

  hny: Libhoney;

  constructor() {
    this.hny = new Libhoney({
      writeKey: "968b34370eaf0d404a2cbbd7b459ead7",
      dataset: "dimers-app",
    })
  }

  sendMessage(messageData: object): void {
    // console.log(`Firing Honeycomb message at ${Date.now()}:`);
    // console.log(messageData);
    this.hny.sendNow(messageData);
  }
}
